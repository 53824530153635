<template>
    <h2>StGB</h2>
    <p>Enter paragraph...</p>
    <input type="text" v-model="para"/>
    <div id="frame"></div>
    <iframe v-if="para!=''" frameborder="0" width=100% height=100%; :src="url"></iframe>
</template>

<script>
import $ from 'jquery'

export default {
    name: 'StGB',
    methods: {
        getLaw() {
            let page = `https://dejure.org/gesetze/StGB/${this.para}.html #alpha`;
            console.log("loading page: " + page);
            $('#frame').load(page)
        }
    },
    computed: {
        url() {
            return `https://dejure.org/gesetze/StGB/${this.para}.html`
        }
    },
    data() {
        return {
            para: '',
        }
    }
}
</script>

<style scoped>
iframe {
    width: 100%;
    height: 1000px;
}

input {
  margin-bottom: 10px;
}
</style>