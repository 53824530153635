import { createRouter, createWebHistory } from 'vue-router'
import BGB from '../views/BGB.vue'
import StGB from '../views/StGB.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: BGB
  },
  {
    path: '/bgb',
    name: 'BGB',
    component: BGB
  },
  {
    path: '/stgb',
    name: 'StGB',
    component: StGB
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
