<template>
    <header :class="{ 'scrolled-nav' : scrolledNav }">
        <nav class="navbar navbar-expand-sm navbar-light bg-light" :class=" { 'fixed-top' : scrolledNav } ">
            <div class="container-fluid">
                <a class="navbar-brand" href="#"><img style="width: 80px;" src="@/assets/logo-vulture.png" alt="logo"></a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                            <router-link class="nav-link" aria-current="page" :to=" { name: 'BGB' } ">BGB</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link class="nav-link" aria-current="page" :to=" { name: 'StGB' } ">StGB</router-link>
                        </li>
                    </ul>
                    <!--<form class="d-flex">
                        <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
                        <button class="btn btn-outline-success" type="submit">Search</button>
                    </form>-->
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
export default {
    Name: 'Navbar',
    computed: {
        loggedIn: function(){
            return this.$store.getters.isLoggedIn;
        }
    },
    data() {
        return {
            scrolledNav: null,
            mobile: null,
            mobileNav: null,
            windowWidth: null
        }
    },
    created() {
        // Check for resize
        /*window.addEventListener('resize', this.checkScreen);
        this.checkScreen();*/
    },
    mounted() {
        // Check for scroll
        //window.addEventListener('scroll', this.updateScroll);
    },
    methods: {
        /*toggleMobileNav() {
            this.mobileNav = !this.mobileNav;
        },*/
        /*updateScroll() {
            const scrollPosition = window.scrollY;
            if (scrollPosition > 120) {
                this.scrolledNav = true;
                return;
            }
            this.scrolledNav = false;
        },*/
        /*checkScreen() {
            this.windowWidth = window.innerWidth;
            if (this.windowWidth < 768) {
                this.mobile = true;
                return;
            } else {
                this.mobile = false;
                this.mobileNav = false;
                return;
            }
        }*/
    } 
}
</script>

<style scoped>
nav {
   background-image: linear-gradient(to right, #54e7cf, #17d127be); 
}

</style>